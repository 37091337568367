




import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";

import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";
import { ExternalTestConfigModel } from "@/ab-tests/models/ExternalTest";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";
import { DictionaryType } from "@/shared/models";

@Component
export default class ExternalTestsBaseView extends mixins(
  AppSectionAccessMixin
) {
  checkedStatuses: Array<ConfigsStatus> = [
    ConfigsStatus.INIT,
    ConfigsStatus.STARTED,
    ConfigsStatus.CONFIG_DISTRIBUTION_FINISHED,
    ConfigsStatus.STATISTICS_RECALCULATION,
    ConfigsStatus.STATISTICS_RECALCULATED,
  ];
  tab = 0;

  get externalTestConfig(): ExternalTestConfigModel {
    return this.$store.state.externalTestStore.externalTestConfig;
  }

  @Watch("applicationId", { immediate: true })
  watchApplicationId() {
    this.$store.dispatch("loadDictionaries", {
      app: this.applicationId,
      dictionaryTypes: [DictionaryType.EXTERNAL_TEST_PROPOSALS],
    });
  }
}
